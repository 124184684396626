.coffeeLink {
    margin-bottom: -10px
}

.container {
    padding: 10px;
}

.logo-link {
    padding-left: 5px;
    padding-right: 5px;
}

.pageIntro {
    padding-top: 10px;    
    margin-bottom: 0px;
}

div.articles div {
    margin-top: 1.5rem;
    border-top: 1pt solid #ccc;
    padding-top: 1.3rem;
}

div.articles p.title {
    margin-bottom: 0.3rem;
}